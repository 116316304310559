import * as React from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useNavigate } from 'react-router-dom';

export default function APIDoc() {
  const navigate = useNavigate()
   

  return (
    <div style={{ display: "flex", height: "100vh" , marginTop:"-45px", marginLeft :"-45px", color:"black" }}>
      <Sidebar className="app">
        <Menu>
          <MenuItem component={<Link to="/intro" className="link" />} className="menu1" >
           Introduction
          </MenuItem>
          <MenuItem > Creating an Account</MenuItem>
          <MenuItem> Getting an API key</MenuItem>
          <MenuItem> Endpoint URLs</MenuItem>
          <SubMenu label="API ENDPOINTS">
            <MenuItem component={<Link to="/account" className="link" />}> Accounts </MenuItem>
            <MenuItem component={<Link to="/contract" className="link" />} > Contracts </MenuItem>
            <MenuItem> Transactions </MenuItem>
            <MenuItem> Blocks </MenuItem>
            <MenuItem> Logs </MenuItem>
            <MenuItem> Geth Proxy </MenuItem>
            <MenuItem> Tokens </MenuItem>
            <MenuItem> Gas Tracker </MenuItem>
            <MenuItem> Stats </MenuItem>
          </SubMenu>
          <SubMenu label="API PRO">
            <MenuItem> BscScan API PRO </MenuItem>
            <MenuItem> API PRO Endpoints </MenuItem>
          </SubMenu>
          <SubMenu label="TUTORIALS">
            <MenuItem> Integrating Google Sheets </MenuItem>
            <MenuItem> Verifying Contracts Programmatically </MenuItem>
          </SubMenu>
          <SubMenu label="MISC TOOLS & UTILITIES">
            <MenuItem> Libraries </MenuItem>
            <MenuItem> Plugins </MenuItem>
            <MenuItem> Public RPC Nodes </MenuItem>
          </SubMenu>
        </Menu>
      </Sidebar>
    </div>
  );
}