import React from "react";

const Account = () =>{
 return(
    <h1>
        Hello
    </h1>
 )
}

export default Account;