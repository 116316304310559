import { Grid } from '@mui/material';
import {ethers} from 'ethers'
import { useEffect, useState } from 'react';

type MyComponentProps = {
  address :any;
};
const ContractData: React.FC<MyComponentProps> = ({address}) => {

  const [contractBytecode, setContractBytecode] = useState('');

  const fetchContractBytecode = async () => {
    try {
      // Connect to an Ethereum node (replace with your actual node URL)
      const provider = new ethers.providers.JsonRpcProvider('http://b4hit-l1-node-backend.appworkdemo.com/archive');

      // Fetch the contract's bytecode using eth_getCode
      const bytecode = await provider.send('eth_getCode', [address, 'latest']);
      console.log(bytecode,"bytecode");
      

      setContractBytecode(bytecode);
    } catch (error) {
      console.error('Error:', error);
    }
  };
useEffect(()=>{
  fetchContractBytecode()
})

  return (
    <>
    <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className="card">
          Contract Creation Code {contractBytecode}

            {/* <div className="table__pagination">
                            <h3>Page: {currentPage}</h3>
                            <div className="btn-wrape">
                                { currentPage > 1 && (
                                    <button
                                        className="btn"
                                        onClick={() => setCurrentPage(prevPage => prevPage - 1)}
                                    >
                                        <i className="bx bx-left-arrow-alt"></i> Back
                                    </button>
                                )}
                                <button
                                    className="btn"
                                    onClick={() => setCurrentPage(prevPage => prevPage + 1)}
                                >
                                    Next <i className="bx bx-right-arrow-alt"></i>
                                </button>
                            </div>
                        </div> */}
          </div>

          {/* {info.contract && info.contract !== "0x" && (
            <div className="">
              <div className="card address-info__card">
                <h1>
                  <span className="theme-color">Contract Code:</span>
                </h1>
                {info.contract}
              </div>
            </div>
          )} */}
        </Grid>
      </Grid>
    </>
  )
}

export default ContractData