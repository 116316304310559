import React from "react";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Layout from './components/layout/Layout';

const App: React.FC = () => {
    return (
        <Layout />
    )
};

export default App;