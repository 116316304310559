import Web3 from "web3";
import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";

import useLoading from "../../../hooks/loading";

import {
  getAddressInfo,
  getNormalTransactions,
  getAddressDetails,
} from "./address.controller";

import Loading from "../../loading/Loading";
import NotFound from "../../404/NotFound";
import Table from "../../table/Table";
import { ethers } from "ethers";
import "./address.scss";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import AddressData from "./AddressData";

interface TX {
  error: boolean;
  hash: string;
  from: string;
  to: string;
  age: number | string;
  value: number | string;
  token?: string;
}

const Address: React.FC = () => {
  const { address = "" } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useLoading(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [addressDataList, setaddressDataList] = useState<{
    [x: string]: any;
    normal: Array<{ page: number; txs: TX[] }>;
    bep20: Array<{ page: number; txs: TX[] }>;
    internal: Array<{ page: number; txs: TX[] }>;
  }>({
    normal: [],
    bep20: [],
    internal: [],
  });
  const [transactions, setTransactions] = useState<{
    normal: Array<{ page: number; txs: TX[] }>;
    bep20: Array<{ page: number; txs: TX[] }>;
    internal: Array<{ page: number; txs: TX[] }>;
  }>({
    normal: [],
    bep20: [],
    internal: [],
  });
  const [info, setInfo] = useState<{
    bnbPrice: number;
    contract: string;
    balance: string;
    balanceUsd: string;
    txCount: number;
    tokenName: any;
    tokenSymbol: any;
    totalSupply: any;
  }>({
    bnbPrice: 0,
    contract: "",
    balance: "",
    balanceUsd: "",
    txCount: NaN,
    tokenName: "",
    tokenSymbol: "",
    totalSupply: "",
  });

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    getAddressInfo(address).then((info) => setInfo(info));
  }, [address]);

  useEffect(() => {
    fetchTransactions();
    getAddressDetails(address, currentPage, rowsPerPage).then((res: any) => {
      setaddressDataList(res.data.transactions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash, transactions, address, currentPage, rowsPerPage]);

  useEffect(() => {
    setTransactions({
      normal: [],
      bep20: [],
      internal: [],
    });
  }, [address]);

  const handleChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const fetchTransactions = async () => {
    setLoading(true);
    switch (location.hash) {
      case "":
      case "#txs":
      case "#transactions":
        if (
          !transactions.normal.find((offset) => offset.page === currentPage)
        ) {
          await getNormalTransactions(currentPage, address).then((txs) => {
            setTransactions((prevTransactions) => {
              return {
                ...prevTransactions,
                normal: [
                  ...prevTransactions.normal,
                  { page: currentPage, txs },
                ],
              };
            });
          });
        }
        break;
      default:
        break;
    }
    setLoading(false);
  };

  if (!address || !Web3.utils.isAddress(address))
    return <NotFound message="Address Not Found" />;

  if (loading) return <Loading />;

  return (
    <section className="address-info">
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className="card">
            <div className="address-info__card">
              <h2>
                <span className="theme-color">Address:</span> {address}
              </h2>
              <h2>
                <span className="theme-color">Balance:</span>{" "}
                {info.balance || "?"} BNB ({info.balanceUsd || "?"}$)
              </h2>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className="card">
            <div className="address-info__card">
              <h2>
                <span className="theme-color">TotalSupply:</span>{" "}
                {info.totalSupply} {info.tokenSymbol}
              </h2>
              <h2>
                <span className="theme-color">Token Name:</span>{" "}
                {info.tokenName} 
              </h2>
            </div>
          </div>
        </Grid>
      </Grid>
      <AddressData transactions={transactions} location={location.hash} addressDataList={addressDataList} currentPage={currentPage} info={info} address={address} />
      
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className="card address-info__card powered-by">
            <div className="address-info__footer">
              <p>Powered by B4HitScan APIs</p>
            </div>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default Address;
