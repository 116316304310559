import { Box, Button } from "@mui/material";
import { Grid } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../table/Table";
import Web3 from "web3";
import ContractData from "./ContractData";

type MyComponentProps = {
  transactions: any;
  location: any;
  addressDataList: any;
  currentPage: any;
  info: any;
  address :any;
};

const AddressData: React.FC<MyComponentProps> = ({
  transactions,
  location,
  addressDataList,
  currentPage,
  info,
  address
}) => {
  const [transferData, setTransferData] = useState(true);
  const [contractData, setContractData] = useState(false);

  const changeTransferInfo = () => {
    setTransferData(true);
    setContractData(false);
  };

  const changeContractInfo = () => {
    setTransferData(false);
    setContractData(true);
  };
  return (
    <>
      <Box sx={{marginBottom:"10px"}}>
      <Button
        className="btn btn-primary m-2 first-btn"
        type="submit"
        sx={{marginRight:"10px"}}
        onClick={changeTransferInfo}
      >
        Transactions
      </Button>
      <Button
        type="reset"
        className="btn btn-outline-secondary reset-btn"
        onClick={changeContractInfo}
      >
        Contract
      </Button>
      </Box>

      {transferData?<Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className="card">
            {["", "#txs", "#transactions"].includes(location) &&
              transactions.normal.find(
                (list: any) => list.page === currentPage
              ) && (
                <div>
                  <div className="card__header">
                    <h3>Transactions: {addressDataList.length}</h3>
                  </div>
                  <div className="card__body">
                    <Table
                      thead={() => {
                        return (
                          <tr>
                            <th>Hash</th>
                            <th>Age</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Value</th>
                          </tr>
                        );
                      }}
                      tbody={addressDataList?.map((tx: any) => {
                        return () => {
                          return (
                            <tr key={tx?.transaction_hash}>
                              <td>
                                <Link
                                  target="_blank"
                                  to={`/tx/${tx?.transaction_hash}`}
                                >
                                  {tx?.transaction_hash.slice(0, 10) + "..."}
                                </Link>
                              </td>
                              <td>{tx?.timestamp} ago</td>
                              <td>
                                <Link
                                  target="_blank"
                                  to={`/address/${tx?.from}`}
                                >
                                  {tx?.from.slice(0, 20) + "..."}
                                </Link>
                              </td>
                              <td>
                                <Link target="_blank" to={`/address/${tx?.to}`}>
                                  {tx?.to ? tx?.to.slice(0, 20) + "..." : "-"}
                                </Link>
                              </td>
                              <td>
                                {Web3.utils.fromWei(
                                  tx?.value.toString(),
                                  "ether"
                                )}
                              </td>
                            </tr>
                          );
                        };
                      })}
                      limit={20}
                    />
                  </div>
                </div>
              )}

            {/* <div className="table__pagination">
                            <h3>Page: {currentPage}</h3>
                            <div className="btn-wrape">
                                { currentPage > 1 && (
                                    <button
                                        className="btn"
                                        onClick={() => setCurrentPage(prevPage => prevPage - 1)}
                                    >
                                        <i className="bx bx-left-arrow-alt"></i> Back
                                    </button>
                                )}
                                <button
                                    className="btn"
                                    onClick={() => setCurrentPage(prevPage => prevPage + 1)}
                                >
                                    Next <i className="bx bx-right-arrow-alt"></i>
                                </button>
                            </div>
                        </div> */}
          </div>

          {info.contract && info.contract !== "0x" && (
            <div className="">
              <div className="card address-info__card">
                <h1>
                  <span className="theme-color">Contract Code:</span>
                </h1>
                {info.contract}
              </div>
            </div>
          )}
        </Grid>
      </Grid>:<ContractData address={address} />}
    </>
  );
};

export default AddressData;
