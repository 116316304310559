import { Box, Typography } from "@mui/material";
import React from "react";

const PaymentGateWay =() =>{
    return(
      <Box>
        <Typography className="container-wrape title-text" variant="h4" component="h6" align="center" >
                <b>Payment GateWay Coming Soon .....</b>
            </Typography>
      </Box>
    ); 
}

export default PaymentGateWay;