import React from "react";

const ContractAPI = () =>{
    return(
     <h1>

     </h1>
    );
}

export default ContractAPI;