import Web3 from "web3";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "../../table/Table";

import { getPrice } from "../../../services/binance";
import {
  getLatestBlock
} from "../../../services/web3";


import "../../pages/home/home.scss";

const AllTransactions: React.FC = () => {
  const [latestTransactions, setLatestTransactions] = useState<
    Array<{
      hash: string;
      blocknumber: number | string;
      from: string;
      to: string | null;
      value: string;
      gasPrice: number;
    }>
  >([]);

  useEffect(() => {
    const updateInfo = async () => {
      const bnbPrice = await getPrice("BNB", "USDT").then(
        (quote) => Number(quote.price).toFixed(2) + "$"
      );
      const block = await getLatestBlock();

      const updateTables = () => {
        setLatestTransactions((prevTransactions) => {
          const prevHashes = prevTransactions.map((tx) => tx.hash);
          const newTransactions = block.transactions
            .filter((tx) => !prevHashes.includes(tx.hash))
            .map((tx) => {
                const gasInEther = (Number(tx.gas) * Number(tx.gasPrice)) / 1e18;
              return {
                hash: tx.hash,
                blocknumber: block.number.toString(),
                from: tx.from,
                to: tx.to,
                value: tx.value,
                gasPrice: gasInEther,
              };
            });
          return [...newTransactions, ...prevTransactions];
        });
      };
      updateTables();
    };

    updateInfo();
    const cardInfoHandler = setInterval(updateInfo, 2500);

    return () => clearInterval(cardInfoHandler);
  }, []);

  return (
            <div className="card">
              <div className="card__header">
                <h3>Latest Transactions:</h3>
              </div>
              <div className="card__body">
                <Table
                  thead={() => {
                    return (
                      <tr>
                        <th>Tx</th>
                        <th>Block Number</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Value</th>
                        <th>GasPrice</th>
                      </tr>
                    );
                  }}
                  tbody={latestTransactions.map((tx) => {
                    return () => {
                      return (
                        <tr key={tx.hash}>
                          <td>
                            <Link to={`/tx/${tx.hash}`}>
                              {tx.hash.slice(0, 10) + "..."}
                            </Link>
                          </td>
                          <td>
                            <Link to={`${tx.blocknumber}`}>
                              {tx.blocknumber}
                            </Link>
                          </td>
                          <td>
                            <Link to={`/address/${tx.from}`}>
                              {tx.from.slice(0, 20) + "..."}
                            </Link>
                          </td>
                          <td>
                            <Link to={`/address/${tx.to}`}>
                              {tx.to ? tx.to.slice(0, 20) + "..." : "-"}
                            </Link>
                          </td>
                          <td>{Web3.utils.fromWei(tx.value, "ether")}</td>
                          <td>{tx.gasPrice.toFixed(6)}</td>
                        </tr>
                      );
                    };
                  })}
                  limit={10}
                  pagesLimit={1000}
                />
              </div>
            </div>
  );
};

export default AllTransactions;
